import { formatShortDateWithWeekday } from "@clipboard-health/date-time";
import { InternalLink, Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Badge, Button, Card, CardContent, Chip, Stack } from "@mui/material";
import { APP_V2_APP_EVENTS, formatUsPhoneNumber } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { GRAY_00 } from "@src/appV2/lib/Theme/constants";
import { getPlacementDetailsPathWithoutPlacementCandidate } from "@src/appV2/redesign/Placements/paths";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { differenceInMinutes, format } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import pluralize from "pluralize";

import { type InterviewWithDetails } from "../../api/useFetchPaginatedInterviews";
import { getBookingSchedule } from "../../utils";
import { InterviewChatWithWorkplace } from "./InterviewChatWithWorkplace";
import { InterviewIncentiveDialog } from "./InterviewIncentiveDialog";

interface InterviewCardProps {
  interview: InterviewWithDetails;
  chatChannels: SendBird.GroupChannel[];
}

export function InterviewCard(props: InterviewCardProps) {
  const { interview, chatChannels } = props;
  const worker = useDefinedWorker();

  const schedule = getBookingSchedule("Interview", interview.start, interview.end, worker.tmz);

  const startTime = format(interview.start, "hh:mm a");
  const endTime = format(interview.end, "hh:mm a");
  const duration = differenceInMinutes(interview.end, interview.start);

  const workerTypes = interview.placement?.workerTypes;
  const currentChannel = chatChannels.find(
    (channel) =>
      channel.url ===
      `${interview.workplace?.id ?? ""}_${worker.userId}_${interview.placement?.id ?? ""}`
  );

  const unreadMessageCount = currentChannel?.unreadMessageCount ?? 0;

  return (
    <Card variant="outlined">
      <CardContent
        sx={{
          paddingX: 3,
          paddingY: 2,
          "&:last-child": {
            paddingBottom: 2,
          },
        }}
      >
        <Stack spacing={2}>
          {isDefined(workerTypes) && (
            <Chip
              label={`Interview for ${
                workerTypes.length > 1
                  ? `${workerTypes[0]} + ${workerTypes.length - 1} more`
                  : workerTypes[0]
              } ${pluralize("job", workerTypes.length)}`}
              size="small"
              sx={{
                width: "fit-content",
              }}
            />
          )}
          <Stack spacing={1}>
            <Stack direction="row" alignItems="baseline" spacing={1}>
              {isDefined(schedule) && (
                <Text variant="h3" color="#1c69d1">
                  {schedule}
                </Text>
              )}
              <Text variant="h3">{formatShortDateWithWeekday(interview.start)}</Text>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
              <Text>
                {startTime} - {endTime}
              </Text>
              <Text sx={{ opacity: 0.6 }}>
                {duration} {pluralize("min", duration)}
              </Text>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Text
                variant="body2"
                color={(theme) => theme.palette.success.main}
                sx={{ fontWeight: 600 }}
              >
                $20 for travel expenses
              </Text>
              <InterviewIncentiveDialog />
            </Stack>
          </Stack>
          {isDefined(interview.workplace) && (
            <Stack spacing={0.25}>
              <Stack direction="row" justifyContent="space-between" spacing={2}>
                <Text
                  variant="body2"
                  color={GRAY_00}
                  sx={{
                    fontWeight: 600,
                  }}
                >
                  {interview.workplace.name}
                </Text>
                <Badge color="error" badgeContent={unreadMessageCount} variant="dot">
                  <InterviewChatWithWorkplace
                    placementId={interview.placementId}
                    workplaceId={interview.workplace.id}
                  />
                </Badge>
              </Stack>
              <Text variant="body2" maxWidth="80%">
                {interview.workplace.formattedAddress}
              </Text>
              <Stack spacing={0.5}>
                <Text variant="body2">{interview.workplace.distance} miles away</Text>
                {interview.workplace.phone && (
                  <Text variant="body2">{formatUsPhoneNumber(interview.workplace.phone)}</Text>
                )}
              </Stack>
            </Stack>
          )}

          <Button
            fullWidth
            size="small"
            variant="contained"
            component={InternalLink}
            to={getPlacementDetailsPathWithoutPlacementCandidate(interview.placementId)}
            onClick={() => {
              logEvent(APP_V2_APP_EVENTS.BOOKED_INTERVIEW_CLICKED, {
                workerId: worker.userId,
                placementDetails: interview.placement,
                interviewDetails: {
                  start: zonedTimeToUtc(interview.start, worker.tmz).toISOString(),
                  end: zonedTimeToUtc(interview.end, worker.tmz).toISOString(),
                  status: interview.status,
                  id: interview.id,
                },
              });
            }}
          >
            View Job Details
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
}
